import { Stack, Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import filterSectionLinksByPermissions from 'utils/filterSectionLinksByPermissions';

export interface MyAccountMenuItem {
  icon: ReactElement;
  label: string;
  pathname: string;
}

const MyAccountMenu = () => {
  const pathMatch = useMatch('/account/:item');
  const navigate = useNavigate();
  const isCurrentPathname = (item: MyAccountMenuItem) =>
    pathMatch?.pathname === item.pathname;
  const permissions = JSON.parse(process.env.REACT_APP_PERMISSIONS as string);
  const newSections = filterSectionLinksByPermissions(permissions);
  return (
    <>
      {newSections.map((section) => {
        const isCurrentLink = isCurrentPathname(section);
        return (
          <Stack
            key={section.label}
            sx={{
              alignItems: 'center',
              bgcolor: isCurrentLink ? 'common.black' : 'transparent',
              color: isCurrentLink ? 'grey.100' : 'grey.300',
              cursor: 'pointer',
              flexDirection: 'row',
              gap: 3,
              height: 60,
              px: 4,
              width: '100%',
            }}
            onClick={() => navigate(section.pathname)}
          >
            {section.icon}
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: isCurrentLink ? 'primary' : 'secondary',
              }}
            >
              {section.label}
            </Typography>
          </Stack>
        );
      })}
    </>
  );
};

export default MyAccountMenu;
